<template>
  <page-header-wrapper>
    <div>
      <a-card class="card" title="基本信息" :bordered="false">
        <repository-form ref="repository" :showSubmit="false" @emitEx="getHousesLink" />
      </a-card>
      <a-card class="card" title="报备规则" :bordered="false">
        <task-form ref="task" :showSubmit="false" />
      </a-card>
      <a-card class="card" title="分佣规则" :bordered="false">
        <cent-form ref="cent" :showSubmit="false" />
      </a-card>
      <a-card class="card" title="案场规则" :bordered="false">
        <field-form ref="field" :showSubmit="false" />
      </a-card>
      <a-card class="card" title="渠道规则" :bordered="false">
        <channel-form ref="channel" :showSubmit="false" />
      </a-card>
      <a-card class="card" title="特殊规则" :bordered="false">
        <special-form ref="special" :showSubmit="false" />
      </a-card>

      <!-- table -->
      <a-card class="card" title="定向发布" :bordered="false">
        <a-switch @change="brokerSwitch" :checked="brokerSet.switch" />
        <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-form-item v-if="brokerSet.switch" label="经纪人">
            <m-select ref="selectBroker" :id="this.id" @emitBroker="fetchBroker"></m-select>
          </a-form-item>
        </a-form>
      </a-card>

      <!-- fixed footer toolbar -->
      <footer-tool-bar :is-mobile="isMobile" :collapsed="sideCollapsed">
        <span class="popover-wrapper">
          <a-popover
            title="表单校验信息"
            overlayClassName="antd-pro-pages-forms-style-errorPopover"
            trigger="click"
            :getPopupContainer="trigger => trigger.parentNode"
          >
            <template slot="content">
              <li
                v-for="item in errors"
                :key="item.key"
                @click="scrollToField(item.key)"
                class="antd-pro-pages-forms-style-errorListItem"
              >
                <a-icon type="cross-circle-o" class="antd-pro-pages-forms-style-errorIcon" />
                <div class="">{{ item.message }}</div>
                <div class="antd-pro-pages-forms-style-errorField">{{ item.fieldLabel }}</div>
              </li>
            </template>
            <span class="antd-pro-pages-forms-style-errorIcon" v-if="errors.length > 0">
              <a-icon type="exclamation-circle" />{{ errors.length }}
            </span>
          </a-popover>
        </span>
        <a-button type="primary" @click="validate" :loading="btn">提交</a-button>
      </footer-tool-bar>
    </div>
  </page-header-wrapper>
</template>

<script>
import moment from 'moment'
import RepositoryForm from './RepositoryForm'
import TaskForm from './TaskForm'
import ChannelForm from './channel'
import SpecialForm from './special'
import FieldForm from './theField'
import CentForm from './centCommission'
import FooterToolBar from '@/components/FooterToolbar'
import { baseMixin } from '@/store/app-mixin'
import { StandardFormRow } from '@/components'
import { addTasks, findByIdTasks, editTasks, inTasks, appointPublish } from '@/api/tasks'
import MSelect from './components/MSelect'
// import { delete } from 'vue/types/umd'
// import { delete } from 'vue/types/umd'

const fieldLabels = {
  houses: '楼盘',
  dateRange: '有效期',
  countSum: '总套数',
  feature: '特色卖点'
}

export default {
  name: 'AdvancedForm',
  mixins: [baseMixin],
  components: {
    FooterToolBar,
    RepositoryForm,
    TaskForm,
    CentForm,
    ChannelForm,
    SpecialForm,
    FieldForm,
    StandardFormRow, // search broker
    MSelect
  },
  data() {
    return {
      noticeTitle: '任务管理',
      id: '',
      loading: false,
      btn: false,
      memberLoading: false,
      housesLink: '',
      // user
      user: {},
      // 定向选择经纪人
      indeterminate: true,
      checkAll: false,
      // 定向发布
      gridSet: {
        label: { lg: { span: 24 }, sm: { span: 24 } },
        wraper: { lg: { span: 24 }, sm: { span: 24 } }
      },
      broker: [],
      brokerSet: {
        visible: true,
        switch: false
      },
      // table
      columns: [
        {
          title: '成员姓名',
          dataIndex: 'name',
          key: 'name',
          width: '20%',
          scopedSlots: { customRender: 'name' }
        },
        {
          title: '手机号',
          dataIndex: 'telId',
          key: 'telId',
          width: '20%',
          scopedSlots: { customRender: 'telId' }
        },
        {
          title: '所属部门',
          dataIndex: 'department',
          key: 'department',
          width: '40%',
          scopedSlots: { customRender: 'department' }
        },
        {
          title: '操作',
          key: 'action',
          scopedSlots: { customRender: 'operation' }
        }
      ],

      errors: []
    }
  },
  computed: {
    serial() {
      return this.$store.state.env.serial
    },
    userId() {
      return this.$store.state.user.info.id
    }
  },
  created() {
    if (this.$route.query.id !== undefined) {
      this.id = this.$route.query.id
    }
  },
  mounted() {
    console.log(this.$store.state, 'state store')
    if (this.$store.state.user && this.$store.state.user.info) {
      this.user = this.$store.state.user.info
    }
    if (this.id !== undefined && this.id !== '') {
      console.log(this.id)
      const requestParameters = {
        id: this.id
      }
      findByIdTasks(requestParameters)
        .then(res => {
          console.log(res.isAppoint, 'res.isAppoint');
          if (res.isAppoint) {
            this.brokerSet.switch = true
          } else {
            this.brokerSet.switch = false
          }
          const {
            $refs: { repository, task, cent, field, channel, special }
          } = this
          const requestParameters = {
            id: this.id,
            name: 'house'
          }
          const taskHouseLink = res._links.house.href
          this.housesLink = taskHouseLink.slice(0, taskHouseLink.indexOf('tasks')) + 'houses/' + res.houseId
          console.log(res, 'house res')
          inTasks(requestParameters)
            .then(tasksRes => {
              repository.form.setFieldsValue({
                houses: tasksRes.name
              })
            })
          // 基本信息
          repository.form.setFieldsValue({
            name: res.name,
            house: res._links.house.href,
            // startTime: res.startTime,
            // endTime: res.endTime,
            startTime: moment(new Date(res.startTime))
              .format('HH:mm:ss')
              .toString(),
            endTime: moment(new Date(res.endTime))
              .format('HH:mm:ss')
              .toString(),
            dateRange: [
              moment(new Date(res.startTime)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/'),
              moment(new Date(res.endTime)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/')
            ],
            total: res.total,
            feature: res.feature
          })
          console.log(moment(new Date(res.startTime)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/'))
          console.log(
            moment(new Date(res.openTimeStart)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/'),
            '--------------------------------------------'
          )
          // 报备规则
          task.form.setFieldsValue({
            reportStartTime: moment(new Date(res.reportStartTime)).format('YYYY-MM-DD HH:mm:ss').replace(/-/g, '/'),
            protectDays: res.protectDays,
            clientOrderProtectDays: res.clientOrderProtectDays,
            clientOrderValidDays: res.clientOrderValidDays,
            reportValidType: res.reportValidType === 0 ? '0' : '1',
            // openTimeStart: res.openTimeStart ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()} ${
            //   res.openTimeStart
            // }` : null,
            // openTimeEnd: res.openTimeEnd ? `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()} ${
            //   res.openTimeEnd
            // }` : null,
            openTimeStart: res.openTimeStart ? res.openTimeStart : null,
            openTimeEnd: res.openTimeEnd ? res.openTimeEnd : null,
            protectReset: res.protectReset === true ? 'true' : 'false',
            reportRemark: res.reportRemark
          })
          if (res.clientOrderProtectDays === -1) {
            task.form.setFieldsValue({
              protectSelect: 1
            })
          } else {
            this.$refs.task.orderProtectDaysShow = true
            task.form.setFieldsValue({
              protectSelect: 0
            })
          }
          // 分佣规则
          cent.form.setFieldsValue({
            ruleCommission: res.ruleCommission
          })
          // 案场规则
          field.form.setFieldsValue({
            ruleScene: res.ruleScene
          })
          // 渠道规则
          channel.form.setFieldsValue({
            ruleDistributor: res.ruleDistributor
          })
          // 特殊规则
          special.form.setFieldsValue({
            ruleSpecial: res.ruleSpecial
          })
          console.log(res, 'tasksData')
        })
    }
  },
  methods: {
    getHousesLink(data) {
      this.housesLink = data
    },
    handleSubmit(e) {
      e.preventDefault()
    },

    // 最终全页面提交
    validate() {
      const {
        $refs: { repository, task, cent, field, channel, special }
      } = this
      const repositoryForm = new Promise((resolve, reject) => {
        repository.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const taskForm = new Promise((resolve, reject) => {
        task.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const centForm = new Promise((resolve, reject) => {
        cent.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const fieldForm = new Promise((resolve, reject) => {
        field.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const channelForm = new Promise((resolve, reject) => {
        channel.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })
      const specialForm = new Promise((resolve, reject) => {
        special.form.validateFields((err, values) => {
          if (err) {
            reject(err)
            return
          }
          resolve(values)
        })
      })

      // clean this.errors
      this.errors = []
      Promise.all([repositoryForm, taskForm, centForm, fieldForm, channelForm, specialForm])
        .then(values => {
          console.log(this.data, '经纪人')
          console.log(values, 'values')
          const requestParameters = {
            publisher: this.serial + '/rest/users/' + this.userId,
            // 基本信息
            name: values[0].name,
            house: this.housesLink,
            startTime: moment(new Date(values[0].dateRange[0])).format('YYYY-MM-DD HH:mm:ss'),
            endTime: moment(new Date(values[0].dateRange[1])).format('YYYY-MM-DD HH:mm:ss'),
            total: values[0].total,
            feature: values[0].feature,
            // 报备规则
            reportStartTime: moment(new Date(values[1].reportStartTime)).format('YYYY-MM-DD HH:mm:ss'),
            protectDays: values[1].protectDays,
            clientOrderProtectDays: values[1].clientOrderProtectDays,
            clientOrderValidDays: values[1].clientOrderValidDays,
            reportValidType: values[1].reportValidType,
            protectReset: values[1].protectReset,
            // openTimeStart: moment(new Date(values[1].openTimeStart)).format('HH:mm:ss'),
            // openTimeEnd: moment(new Date(values[1].openTimeEnd)).format('HH:mm:ss'),
            openTimeStart: values[1].openTimeStart,
            openTimeEnd: values[1].openTimeEnd,
            reportRemark: values[1].reportRemark,
            // 分佣规则
            ruleCommission: values[2].ruleCommission,
            // 案场规则
            ruleScene: values[3].ruleScene,
            // 渠道规则
            ruleDistributor: values[4].ruleDistributor,
            // 特殊规则
            ruleSpecial: values[5].ruleSpecial
          }
          // const openTimeStart = new Date(values[1].openTimeStart).toString()
          // const openTimeEnd = new Date(values[1].openTimeEnd).toString()
          // requestParameters.openTimeStart = openTimeStart.slice(openTimeStart.indexOf(' ') + 1)
          // requestParameters.openTimeEnd = openTimeEnd.slice(openTimeEnd.indexOf(' ') + 1)
          console.log(requestParameters, 'requestParameters')
          console.log(this.id, 'this.id')
          if (this.brokerSet.switch) {
            requestParameters.isAppoint = true
          } else {
            requestParameters.isAppoint = false
          }
          if (this.id !== undefined && this.id !== '') {
            this.btn = true
            requestParameters.id = this.id
            editTasks(requestParameters)
              .then(res => {
                if (this.brokerSet.switch) {
                  this.editBroker(res.id)
                } else {
                  this.btn = false
                  this.$router.push({
                    path: '/task/audit/index'
                  })
                  this.$notification.success({
                    message: this.noticeTitle,
                    description: '编辑成功'
                  })
                }                
              })
              .catch(() => {
                this.btn = false
              })
          } else {
            this.btn = true
            addTasks(requestParameters)
              .then(res => {
                if (this.brokerSet.switch) {
                  this.editBroker(res.id)
                } else {
                  this.btn = false
                  this.$router.push({
                    path: '/task/audit/index'
                  })
                  this.$notification.success({
                    message: this.noticeTitle,
                    description: '新建成功'
                  })
                }                
              })
              .catch(() => {
                this.btn = false
              })
          }
        })
        .catch(() => {
          const errors = Object.assign({}, repository.form.getFieldsError(), task.form.getFieldsError())
          const tmp = { ...errors }
          this.errorList(tmp)
        })
    },
    editBroker(id) {
      console.log(this.broker);
      const brokerIds = this.broker.map(item => {
        return item.key
      }).join(',') 
      const param = {
        taskId: id,
        brokerIds
      }
      appointPublish(param).then(res => {
        this.$notification['success']({
          message: '发布任务',
          description: '发布成功'
        })
        this.btn = false
        this.$router.push({
          path: '/task/audit/list'
        })
      }).catch(() => {
        this.btn = false
      })
    },
    errorList(errors) {
      if (!errors || errors.length === 0) {
        return
      }
      this.errors = Object.keys(errors)
        .filter(key => errors[key])
        .map(key => ({
          key: key,
          message: errors[key][0],
          fieldLabel: fieldLabels[key]
        }))
    },
    scrollToField(fieldKey) {
      const labelNode = document.querySelector(`label[for="${fieldKey}"]`)
      if (labelNode) {
        labelNode.scrollIntoView(true)
      }
    },
    // 经纪人
    brokerSwitch(checked) {
      this.brokerSet.switch = !this.brokerSet.switch
      if (!this.brokerSet.switch) {
        this.broker = []
      }
      // this.$refs.selectBroker.getAllBroker()
      console.log(`a-switch to ${checked}`)
    },
    fetchBroker(data) {
      this.broker = data
    }
  }
}
</script>

<style lang="less" scoped>
.card {
  margin-bottom: 24px;
}
.popover-wrapper {
  /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
    min-width: 256px;
    max-height: 290px;
    padding: 0;
    overflow: auto;
  }
}
.antd-pro-pages-forms-style-errorIcon {
  user-select: none;
  margin-right: 24px;
  color: #f5222d;
  cursor: pointer;
  i {
    margin-right: 4px;
  }
}
.antd-pro-pages-forms-style-errorListItem {
  padding: 8px 16px;
  list-style: none;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  transition: all 0.3s;

  &:hover {
    background: #e6f7ff;
  }
  .antd-pro-pages-forms-style-errorIcon {
    float: left;
    margin-top: 4px;
    margin-right: 12px;
    padding-bottom: 22px;
    color: #f5222d;
  }
  .antd-pro-pages-forms-style-errorField {
    margin-top: 2px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 12px;
  }
}

// 定向发布经纪人
.antd-pro-components-standard-form-row-index-standardFormRow {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-switch {
  position: absolute;
  top: 18px;
  left: 100px;
}
</style>
