<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" :gutter="16">
      <a-col>
        <standard-form-row grid>
          <a-row>
            <a-col>
              <a-form-item label="任务名称" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
                <a-input
                  placeholder="请输入任务名称"
                  v-decorator="['name', { rules: [{ required: true, message: '请输入任务名称' }] }]"
                />
              </a-form-item>
            </a-col>
            <a-col :md="24">
              <a-form-item label="楼盘" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
                <a-select
                  show-search
                  :value="value"
                  placeholder="请选择楼盘"
                  :default-active-first-option="false"
                  :show-arrow="false"
                  :filter-option="false"
                  :not-found-content="null"
                  @search="handleSearch"
                  @change="handleChange"
                  @focus="findAll"
                  v-decorator="['houses', { rules: [{ required: true, message: '请选择楼盘' }] }]"
                >
                  <a-select-option v-for="d in data" :key="d.link">
                    {{ d.text }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
          </a-row>
        </standard-form-row>
      </a-col>
      <a-col>
        <a-form-item label="有效期" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-range-picker
            style="width: 100%"
            v-decorator="['dateRange', { rules: [{ required: true, message: '请选择有效期' }] }]"
          />
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="总套数" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-input-number :min="0" placeholder="请输入总套数" v-decorator="['total']" />
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="特色卖点" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-input
            type="textarea"
            placeholder="请输入特色卖点"
            :auto-size="{ minRows: 6, maxRows: 9 }"
            v-decorator="[
              'feature',
              {
                rules: [
                  { message: '请输入特色卖点', whitespace: true },
                  { max: areaLimitLength, message: areaLimitText }
                ]
              }
            ]"
          />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit">Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
import { StandardFormRow } from '@/components'
import { queryHouse } from '@/api/house'

export default {
  name: 'RepositoryForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StandardFormRow
  },
  data() {
    return {
      form: this.$form.createForm(this),
      timeout: null,
      currentValue: null,
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      },
      housesSet: {
        visible: true
      },
      // search
      data: [],
      value: undefined
    }
  },
  computed: {
    userId() {
      return this.$store.state.user.info.id
    },
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '基本信息')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    validate(rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    },
    // search
    handleSearch(value) {
      this.fetch(value, data => (this.data = data))
    },
    handleChange(value, node) {
      this.$emit('emitEx', value)
      this.value = value
      this.fetch(value, data => (this.data = data))
    },
    findAll(value) {
      this.fetch('', data => (this.data = data))
    },
    fetch(value, cb) {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.currentValue = value
      this.fake(cb, value)
      this.timeout = setTimeout(this.fake, 300)
    },
    fake(cb, value) {
      const params = {
        userId: this.userId,
        keyword: this.currentValue,
        ...this.maxPagination
      }
      queryHouse(params).then(d => {
        if (!d.success) return
        if (this.currentValue === value) {
          const result = d.data.content
          const data = []
          result.forEach(r => {
            const type = r.propertyType === '别墅' ? '别墅' : r.type === 0 ? '住宅' : '商业'
            const value = r.name + '(' + type + ')'
            data.push({
              id: r.id,
              value: r.name,
              text: value,
              link: this.serial + '/rest/houses/' + r.id
            })
          })
          cb(data)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.antd-pro-components-standard-form-row-index-standardFormRow {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
</style>
