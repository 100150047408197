<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-form-item>
      <a-input
        type="textarea"
        placeholder="请输入分佣规则"
        :auto-size="{ minRows: 6, maxRows: 9 }"
        v-decorator="[
          'ruleCommission',
          {rules: [{ message: '请输入分佣规则', whitespace: true}, { max: areaLimitLength, message: areaLimitText }]}
        ]" />
    </a-form-item>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: 'Money',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: this.$form.createForm(this),
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '基本信息')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    validate (rule, value, callback) {
      const regex = /^user-(.*)$/
      if (!regex.test(value)) {
        callback(new Error('需要以 user- 开头'))
      }
      callback()
    }
  }
}
</script>

<style scoped>

</style>
