<template>
  <a-form @submit="handleSubmit" :form="form" class="form">
    <a-row class="form-row" :gutter="16">
      <a-col>
        <a-form-item label="报备开始时间" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-date-picker
            v-decorator="['reportStartTime', { rules: [{ required: true, message: '请选择报备开始时间'}] }]"
            show-time
            format="YYYY-MM-DD HH:mm"
          />
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="报备有效期" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-radio-group v-decorator="['reportValidType', { rules: [{ required: true, message: '请选择报备有效期'}] }]">
            <a-radio value="0" checked>
              当天
            </a-radio>
            <a-radio value="1">
              24小时
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="再次到访重置保护期" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-radio-group v-decorator="['protectReset', { rules: [{ required: true, message: '请选择客户保护期类型'}] }]">
            <a-radio value="true">
              重置
            </a-radio>
            <a-radio value="false">
              不重置
            </a-radio>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="到访保护期(天)" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-input-number 
            :min="1"
            placeholder="请输入到访保护期(天)"
            v-decorator="[
              'protectDays',
              {rules: [{ required: true, message: '请输入到访保护期' }]}
            ]" />
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="老带新到访保护期(天)" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-row :gutter="12">
            <a-col :span="8">
              <a-select v-decorator="['protectSelect']" placeholder="请选择" @change="changeOrderProtectDays">
                <a-select-option :value="1">
                  永久
                </a-select-option>
                <a-select-option :value="0">
                  指定天数
                </a-select-option>
              </a-select>
            </a-col>
            <a-col v-show="orderProtectDaysShow" :span="16">
              <a-input-number 
                :min="0"
                placeholder="请输入老带新到访保护期(天)"
                v-decorator="[
                  'clientOrderProtectDays',
                  {rules: [{ required: true, message: '请输入老带新到访保护期' }]}
                ]" />
            </a-col>
          </a-row>
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="老带新报备有效期(天)" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-input-number 
            :min="0"
            placeholder="请输入老带新报备有效期(天)"
            v-decorator="[
              'clientOrderValidDays',
              {rules: [{ required: true, message: '请输入老带新报备有效期' }]}
            ]" />
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="接访时间" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-form-item
            :style="{ display: 'inline-block', width: 'calc(50% - 12px)', height: '20px' }"
          >
            <a-time-picker v-decorator="['openTimeStart']" format="HH:mm" style="width: 100%" />
          </a-form-item>
          <span :style="{ display: 'inline-block', width: '24px', textAlign: 'center' }">
            -
          </span>
          <a-form-item :style="{ display: 'inline-block', width: 'calc(50% - 12px)', height: '20px' }">
            <a-time-picker v-decorator="['openTimeEnd']" format="HH:mm" style="width: 100%" />
          </a-form-item>
        </a-form-item>
      </a-col>
      <a-col>
        <a-form-item label="报备备注" :labelCol="gridSet.label" :wrapperCol="gridSet.label">
          <a-input
            type="textarea"
            placeholder="请输入报备备注"
            :auto-size="{ minRows: 6, maxRows: 9 }"
            v-decorator="[
              'reportRemark',
              {rules: [{ message: '请输入报备备注', whitespace: true}, { max: areaLimitLength, message: areaLimitText}]}
            ]" />
        </a-form-item>
      </a-col>
    </a-row>
    <a-form-item v-if="showSubmit">
      <a-button htmlType="submit" >Submit</a-button>
    </a-form-item>
  </a-form>
</template>

<script>
export default {
  name: 'TaskForm',
  props: {
    showSubmit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orderProtectDaysShow: false,
      form: this.$form.createForm(this),
      protectDays: '',
      gridSet: {
        label: { lg: { span: 8 }, sm: { span: 8 } },
        wraper: { lg: { span: 10 }, sm: { span: 10 } }
      }
    }
  },
  methods: {
    handleSubmit (e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values, '报备规则')
          // this.$notification['error']({
          //   message: 'Received values of form:',
          //   description: values
          // })
        }
      })
    },
    changeOrderProtectDays(value) {
      console.log(value, 'value');
      if (value === 0) {
        this.orderProtectDaysShow = true
      } else {
        this.orderProtectDaysShow = false
      }
    }
  },
  mounted() {
    console.log(this.form, 'form')
  }
}
</script>

<style scoped>

</style>
