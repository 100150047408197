<template>
  <a-select
    allow-clear
    mode="multiple"
    label-in-value
    placeholder="选择经纪人"
    :value="selectedItems"
    style="width: 100%"
    @change="handleChange"
    option-label-prop="label"
  >
    <a-select-option v-for="item in options" :key="item.value" :value="item.value" :label="item.name">
      {{ item.text }}
    </a-select-option>
  </a-select>
</template>
<script>
import { brokers } from '@/api/broker'
import { findByTaskIdTaskBroker } from '@/api/tasks'
export default {
  name: 'MBrokerSelect',
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      options: [],
      selectedItems: []
    }
  },
  mounted() {
    this.getAllBroker()
    if (this.id) {
      this.taskBrokerRecords(this.id)
    }
  },
  methods: {
    handleChange(selectedItems) {
      console.log(selectedItems, 'selectedItems');
      this.selectedItems = selectedItems
      const selected = selectedItems.map(item => {
        return {
          key: item.key.split(',')[2],
          label: item.label
        }
      })
      this.$emit('emitBroker', selected)
    },
    getAllBroker() {
      brokers()
        .then(res => {
          this.options = res._embedded.brokers.map(item => {
            return {
              name: item.name,
              text: item.name + `(${item.phone})`,
              value: `${item.name},${item.phone},${item.id}`,
              data: item
            }
          })
        })
    },
    taskBrokerRecords(id) {
      const param = {
        taskId: id
      }
      findByTaskIdTaskBroker(param).then(res => {
        this.selectedItems = res._embedded.taskBrokers.map(item => {
          const broker = item._embedded.broker
          console.log(broker);
          return {
            key: `${broker.name},${broker.phone},${broker.id}`,
            label: broker.name
          }
        })
        console.log(this.selectedItems, 'this.selectedItems');
      })
    }
  }
}
</script>
